import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LockClosedIcon,
  ShieldCheckIcon,
  BookOpenIcon,
  BellIcon,
} from '@heroicons/react/20/solid';

import { Button, Spinner, ColorScheme } from 'src/ui/components';
import {
  Welcome,
  Step,
  SetPassword,
  TwoFAuth,
  Notification,
  Tutorials,
} from './components';

import ProtoCallLogo from 'src/assets/images/ProtoCall-Logo.png';
import LflLogo from 'src/assets/images/LFL-logo.png';
import { showErrorToast } from 'src/utils/ToastNotification';

import { useUserVerification, useResetPassword } from './api';
import {
  useTitle,
  useUpdateOnboardingStatus,
  useUpdateUserEmailNotificationSettings,
} from 'src/hooks';
import { useProgressStore } from 'src/hooks/useProgressStore';
import { useSetDefaultDashbordSettings } from './api/useSetDefaultDashboardSettings';

import {
  noDocumentSettingsIds,
  epocValueConversion,
} from 'src/utils/customEmailNotification';
import { userOnBoardingStore } from 'src/store/userOnboardingStore';

import { onBoardingStepsType } from './types/steptypes';

export default function UserOnboarding(): ReactElement {
  useTitle('User onboarding');
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const isNewUser = queryParams.get('isNewUser');
  const code = queryParams.get('code');
  const emailConfirm = queryParams.get('emailConfirm');

  const [password, setPassword] = useState<string>('');

  const { mutations } = useProgressStore();

  const step = userOnBoardingStore(useCallback(state => state.step, []));

  const setUpdateStore = userOnBoardingStore(
    useCallback(state => state.setUpdateStore, []),
  );

  const getBasicUserInfo = userOnBoardingStore(
    useCallback(state => state.fetchUserBasicInfo, []),
  );

  const userDetailsOnLoading = userOnBoardingStore(
    useCallback(state => state.userDetailsOnLoading, []),
  );

  const userDetails = userOnBoardingStore(
    useCallback(state => state.userDetails, []),
  );

  const disableSaveBtn = userOnBoardingStore(
    useCallback(state => state.disableSaveBtn, []),
  );

  const showAuthConfirmation = userOnBoardingStore(
    useCallback(state => state.showAuthConfirmation, []),
  );

  const customEmailNotification = userOnBoardingStore(
    useCallback(state => state.customEmailNotification, []),
  );

  const customEmailNotificationSettings = userOnBoardingStore(
    useCallback(state => state.customEmailNotificationSettings, []),
  );

  const includeEmptyNotification = userOnBoardingStore(
    useCallback(state => state.includeEmptyNotification, []),
  );

  const selectedFrequency = userOnBoardingStore(
    useCallback(state => state.selectedFrequency, []),
  );

  const getNotificationDetailsLoading = userOnBoardingStore(
    useCallback(state => state.getNotificationDetailsLoading, []),
  );

  const userDetailsError = userOnBoardingStore(
    useCallback(state => state.userDetailsError, []),
  );

  useEffect(() => {
    if (userDetailsError && userDetailsError !== '') {
      showErrorToast({
        message: 'Access Denied. Please try again later.',
      });
    }
  }, [userDetailsError]);

  const onBoardingSteps: Array<onBoardingStepsType> = [
    {
      step: 1,
      currentStep: step,
      stepLabel: 'Password',
      stepIcon: <LockClosedIcon className="h-5 w-5" />,
    },
    {
      step: 2,
      currentStep: step,
      stepLabel: 'Two-factor authentication',
      stepIcon: <ShieldCheckIcon className="h-5 w-5" />,
    },
    {
      step: 3,
      currentStep: step,
      stepLabel: 'Notifications',
      stepIcon: <BellIcon className="h-5 w-5" />,
    },
    {
      step: 4,
      currentStep: step,
      stepLabel: 'Tutorials',
      stepIcon: <BookOpenIcon className="h-5 w-5" />,
    },
  ];

  // fetch basic user info
  useQuery(['get-onboarding-user-info', id], () => getBasicUserInfo(id), {
    staleTime: Infinity,
  });

  const { mutate: verifyUserEmail, isLoading: isVerifyUser } =
    useUserVerification();

  const { mutate: resetPassword, isLoading: isPasswordResetting } =
    useResetPassword();

  const { mutate: updateStatus, isLoading: isUpdateStatusLoading } =
    useUpdateOnboardingStatus();

  const { mutate: emailUpdate } = useUpdateUserEmailNotificationSettings();

  const { mutate: saveDashbordSettings } = useSetDefaultDashbordSettings();

  const isSpinnerShowing =
    isVerifyUser ||
    isPasswordResetting ||
    isUpdateStatusLoading ||
    getNotificationDetailsLoading ||
    userDetailsOnLoading ||
    mutations.isFechQrCodeLoading ||
    mutations.isVerifyAuthLoading ||
    mutations.isEmailAsAuthLoading;

  function onSaveHandler() {
    // if current step is Set password
    if (emailConfirm && code && step === 1) {
      verifyUserEmail(
        { id, type: false, code: encodeURI(emailConfirm) },
        {
          onSuccess: () => {
            resetPassword(
              {
                id,
                isNewUserOnBoarding: !!parseInt(isNewUser ? isNewUser : ''),
                code: encodeURI(code),
                password,
              },
              {
                onSuccess: () => {
                  setUpdateStore('step', step + 1);
                  setUpdateStore('showPasswordErr', false);
                  getBasicUserInfo(id); // to update the user's email when onboarding occurs due to change email.
                },
                onError: err => {
                  setUpdateStore('passWordErrMsg', err.message);
                  setUpdateStore('showPasswordErr', true);
                },
              },
            );
          },
          onError: () => {},
        },
      );
    } else if (step === 2) {
      // if current step is Twofactor auth
      // if user having document permission then update status to complted
      if (userDetails?.HasCallDocumentPermission) {
        updateStatus(
          { id, status: 'Completed' },
          {
            onSuccess: () => {
              setUpdateStore('step', step + 1);
              setUpdateStore('showAuthConfirmation', false);
              setUpdateStore('googleAuthCode', '');
              saveDashbordSettings({ id }); // Set clinical resolution as default in home widget.
            },
            onError: () => {},
          },
        );
      } else {
        setUpdateStore('step', step + 2);
        setUpdateStore('showAuthConfirmation', false);
        setUpdateStore('googleAuthCode', '');
      }
    } else if (step === 3) {
      //if current step is notification
      if (id) {
        const updatedCustomSettings = customEmailNotificationSettings?.map(
          item => ({
            ...item,
            EpochTimeValue: epocValueConversion(item?.NotifyTime),
          }),
        );

        const params = {
          id: id,
          payload: {
            ...customEmailNotification,
            CustomEmailNotificationSettings: updatedCustomSettings,
            EmailNotificationSettingsId: selectedFrequency,
            IncludeEmptyNotification:
              selectedFrequency !== null &&
              noDocumentSettingsIds.includes(selectedFrequency)
                ? false
                : includeEmptyNotification,
          },
        };
        emailUpdate(params, {
          onSuccess: () => {
            setUpdateStore('step', step + 1);
          },
        });
      }
    } else {
      navigate('/Home');
    }
  }

  return (
    <div className="flex min-h-0 grow flex-col md:flex-row">
      {isSpinnerShowing && (
        <div className="bg-gray-900/8  absolute inset-0 z-50 grid   place-items-center  backdrop-blur-sm">
          <Spinner size="large" />
        </div>
      )}
      <div className="flex shrink-0 flex-col bg-white shadow-left-panel dark:bg-neutral-800 lg:basis-[max(250px,15%)]">
        <div className="flex justify-between gap-3 p-2 sm:p-3 md:p-5">
          <img
            src={
              process.env.REACT_APP_THEME === 'proteus'
                ? ProtoCallLogo
                : LflLogo
            }
            alt="Logo"
            className="max-h-[25px] self-center dark:mix-blend-screen dark:hue-rotate-180 dark:invert"
          />
          <ColorScheme />
        </div>
        <div className="flex flex-1 justify-evenly gap-4 p-2 sm:justify-center sm:gap-8 sm:p-3 md:flex-col md:p-5">
          {onBoardingSteps.map((item: onBoardingStepsType) => {
            if (
              !(
                userDetails?.HasCallDocumentPermission === false &&
                item?.step === 3
              )
            ) {
              return (
                <Step
                  key={item.step}
                  step={item.step}
                  currentStep={item.currentStep}
                  stepLabel={item.stepLabel}
                  stepIcon={item.stepIcon}
                  stepNumber={
                    userDetails?.HasCallDocumentPermission === false &&
                    item?.step === 4
                      ? 3
                      : item?.step
                  }
                />
              );
            }
          })}
        </div>
        <div className="hidden p-8 md:block" aria-hidden="true" />
      </div>
      <div className="flex min-h-0 grow flex-col ">
        <div className="min-h-0 grow overflow-y-scroll">
          {step === 0 && <Welcome id={id} code={code} />}
          {step === 1 && (
            <SetPassword password={password} setPassword={setPassword} />
          )}
          {step === 2 && <TwoFAuth />}
          {step === 3 && userDetails?.HasCallDocumentPermission === true && (
            <Notification />
          )}
          {step === 4 && <Tutorials />}
        </div>

        {step !== 0 && (
          <div className="shrink-0 border-t border-light-light py-2 dark:border-dark-medium sm:py-3 lg:py-5">
            <div
              className={`container mx-auto flex max-w-2xl px-4 md:px-6 ${
                step === 3 ? 'justify-between' : 'justify-end'
              }`}
            >
              {step === 3 && (
                <Button
                  onClick={() => {
                    setUpdateStore('step', step - 1);
                  }}
                  className={`${
                    step > 4 ? 'pointer-events-none opacity-50' : ''
                  } duration-350`}
                >
                  Back
                </Button>
              )}

              <Button
                onClick={() => onSaveHandler()}
                variant="primary"
                className={`${
                  step > 4 ? 'pointer-events-none opacity-50' : ''
                } duration-350`}
                disabled={disableSaveBtn(step, password, showAuthConfirmation)}
              >
                {step === 4 ? 'Continue to Login' : 'Save and Continue'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
