import React, {
  ReactElement,
  useCallback,
  SyntheticEvent,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  TextField,
  Button,
  Select,
  Spinner,
  CustomCancelModal,
} from 'src/ui/components';

import { useCreateProvider } from '../api';
import { useAuth, useTitle } from 'src/hooks';
import { useMutableObserver } from 'src/hooks/useMutableObserver';

import {
  authenticationType,
  httpMethods,
  formatCreatePayload,
  successMsg,
  updateMsg,
  errorOccuredMsg,
  warningMsg,
  urlPattern,
} from '../utils/resourceHelper';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';
import { cancelWarningMsg } from 'src/utils/appConstants';

import { useAddEditProviderStore } from '../store/addEditProviderStore';
import { useSaveChangesParamStore } from 'src/store/useSaveChangesParamStore';

export default function AddEditResource() {
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { token, tokenType } = useAuth();

  const { mutate: createProvider, isLoading: isCreating } = useCreateProvider();

  const warningMsgRef = useRef<HTMLDivElement | null>(null);

  const { isMessageChanged } = useMutableObserver(warningMsgRef);

  const [showCancelModal, setShowCancelModal] = useState(false);

  const errorMsg = useAddEditProviderStore(
    useCallback(state => state.errorMessage, []),
  );

  const setErrorMessage = useAddEditProviderStore(
    useCallback(state => state.setErrorMessage, []),
  );

  const updateProviderdetails = useAddEditProviderStore(
    useCallback(state => state.updateProviderDetails, []),
  );

  const updateSecretDetails = useAddEditProviderStore(
    useCallback(state => state.updateSecretDetails, []),
  );

  const setSaveClicked = useAddEditProviderStore(
    useCallback(state => state.setSaveClicked, []),
  );

  const clearProviderStore = useAddEditProviderStore(
    useCallback(state => state.clearProviderStore, []),
  );

  const updateUserMode = useAddEditProviderStore(
    useCallback(state => state.updateUserMode, []),
  );
  const disableSave = useAddEditProviderStore(
    useCallback(state => state.disableSave, []),
  );

  const fetchProviderDetails = useAddEditProviderStore(
    useCallback(state => state.fetchProviderDetails, []),
  );

  const isResponseLoading = useAddEditProviderStore(
    useCallback(state => state.isResponseLoading, []),
  );

  const providerDetails = useAddEditProviderStore(
    useCallback(state => state.providerDetails, []),
  );

  const secret = useAddEditProviderStore(
    useCallback(state => state.secret, []),
  );

  const userMode = useAddEditProviderStore(
    useCallback(state => state.userMode, []),
  );

  const saveClicked = useAddEditProviderStore(
    useCallback(state => state.saveClicked, []),
  );

  const checkDataHasChanged = useAddEditProviderStore(
    useCallback(state => state.checkDataHasChanged, []),
  );

  const isDirty = useSaveChangesParamStore(
    useCallback(state => state.isDirty, []),
  );

  const setIsDirty = useSaveChangesParamStore(
    useCallback(state => state.setIsDirty, []),
  );

  const showLoading = isResponseLoading || isCreating;

  useQuery(
    ['get-provider-details', id],
    () => {
      fetchProviderDetails(token, tokenType, id);
    },
    { staleTime: Infinity },
  );

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(['get-provider-details', id], {
        refetchActive: false,
      });
    };
  }, [queryClient, id]);

  useEffect(() => {
    if (location?.pathname.split('/').includes('AddProvider'))
      updateUserMode && updateUserMode('Create');
    if (location?.pathname.split('/').includes('EditProvider'))
      updateUserMode && updateUserMode('Edit');
  }, [location?.pathname, updateUserMode]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
        const confirmLeave = window.confirm(cancelWarningMsg);
        if (confirmLeave) {
          return e;
        } else {
          e.preventDefault();
        }
      }
    };

    if (isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const getUserMode = useMemo(() => {
    if (userMode === 'Create' || userMode === '') return 'Add Provider Details';
    if (userMode === 'Edit') return 'Edit Provider Details';
    return '';
  }, [userMode]);

  useTitle(getUserMode);

  //save or update provider
  function createNewProvider() {
    if (disableSave() === false) {
      return;
    } else {
      const data = formatCreatePayload(providerDetails, secret);
      const params = { payload: data, userMode };

      createProvider(params, {
        onSuccess: () => {
          navigate('/Resources');
          if (userMode === 'Create') showSuccessToast({ message: successMsg });
          else showSuccessToast({ message: updateMsg });
          clearProviderStore();
          queryClient.invalidateQueries(['get-resource-list'], {
            refetchActive: false,
          });
          setIsDirty(false);
        },
        onError: (message: any) => {
          setErrorMessage(
            message?.detail
              ? message?.detail
              : message?.errors['Provider.TimeInterval'] &&
                message?.errors['Provider.TimeInterval'][0]
              ? message?.errors['Provider.TimeInterval'][0]
              : '',
          );
          if (errorMsg === '') {
            showErrorToast({
              message: errorOccuredMsg,
            });
          }
        },
      });
    }
  }

  function handleCancel() {
    queryClient.invalidateQueries(['get-provider-details'], {
      refetchActive: false,
    });
    setIsDirty(false);
    setSaveClicked(false);
    navigate('/Resources');
    clearProviderStore();
  }

  function showValidationModal() {
    if (checkDataHasChanged() === false) {
      setShowCancelModal(true);
    } else handleCancel();
  }

  function renderTextField(
    label: string,
    value: string,
    key: string,
  ): ReactElement {
    return (
      <TextField
        id={label?.toLocaleLowerCase()}
        label={label}
        value={value}
        onChange={e => {
          if (key === 'timeInterval' && parseInt(e.target.value) <= 0) {
            updateProviderdetails(key, '');
            return;
          }
          key === 'Username' || key === 'Password' || key === 'ApiKey'
            ? updateSecretDetails(key, e.target.value)
            : updateProviderdetails(key, e.target.value);
          if (key === 'endpoint' || key === 'timeInterval') {
            setErrorMessage('');
          }
        }}
        validationError={
          key === 'endpoint' && errorMsg?.includes('URL')
            ? errorMsg
            : key === 'timeInterval' && !errorMsg?.includes('URL')
            ? errorMsg
            : ''
        }
        onKeyDown={e => {
          if (
            (e.key.toLocaleLowerCase() === 'e' || e.key === '-') &&
            key === 'timeInterval'
          )
            e.preventDefault();
        }}
        required={true}
        inputSize="medium"
        type={
          key === 'timeInterval'
            ? 'number'
            : key === 'Password'
            ? 'password'
            : 'text'
        }
        min={key === 'timeInterval' ? 1 : ''}
        onBlur={e => {
          if (
            key === 'endpoint' &&
            e.target.value.trim() !== '' &&
            !urlPattern.test(e.target.value.trim())
          ) {
            setErrorMessage('Invalid URL');
          } else {
            setErrorMessage('');
          }
        }}
      />
    );
  }

  function renderSelectField(
    options: Array<{ value: number; label: string }> | undefined,
    label: string,
    key: string,
    value?: number | undefined,
  ): ReactElement {
    return (
      <Select
        id={key}
        className="w-full sm:w-auto"
        options={options}
        value={value}
        onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
          updateProviderdetails(key, e.currentTarget.value);
          if (key === 'authenticationType') {
            updateSecretDetails('Username', '');
            updateSecretDetails('Password', '');
            updateSecretDetails('ApiKey', '');
          }
        }}
        label={label}
        required={true}
      />
    );
  }

  function renderFooter(): ReactElement {
    return (
      <div className="border-t border-light-light bg-white dark:border-dark-medium dark:bg-neutral-800">
        <div
          ref={warningMsgRef}
          className="container mx-auto flex flex-col flex-wrap gap-4 p-2 sm:justify-end md:flex-row"
        >
          {!disableSave() && saveClicked && (
            <div
              className={`flex items-center gap-2 ${
                isMessageChanged ? 'animate-shake' : ''
              }`}
            >
              <span className="relative flex h-3 w-3 shrink-0 items-center justify-center">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
              </span>
              <span className="text-sm font-medium text-red-700 dark:text-red-400">
                {warningMsg}
              </span>
            </div>
          )}
          <div className="flex gap-[1ch]">
            <Button
              className="grow md:grow-0"
              onClick={() => showValidationModal()}
            >
              Cancel
            </Button>
            <Button
              className="grow md:grow-0"
              variant="primary"
              onClick={() => {
                queryClient.invalidateQueries(['get-provider-details'], {
                  refetchActive: false,
                });
                setSaveClicked(true);
                createNewProvider();
              }}
              disabled={
                userMode !== 'Edit'
                  ? providerDetails?.endpoint !== '' &&
                    providerDetails?.endpoint !== undefined &&
                    providerDetails?.endpoint?.length > 0
                    ? !urlPattern.test(providerDetails?.endpoint?.trim())
                    : true
                  : checkDataHasChanged() === true
                  ? true
                  : providerDetails?.endpoint !== '' &&
                    providerDetails?.endpoint !== undefined &&
                    providerDetails?.endpoint?.length > 0
                  ? !urlPattern.test(providerDetails?.endpoint?.trim())
                  : true
              }
            >
              {userMode === 'Edit' ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
        {showCancelModal === true && (
          <CustomCancelModal
            showCancelModal={showCancelModal}
            setShowCancelModal={setShowCancelModal}
            handleCancel={handleCancel}
          />
        )}
      </div>
    );
  }
  return (
    <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
      <div className="flex flex-wrap items-center justify-between gap-2 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:gap-4 sm:px-3 lg:px-4">
        <div className="flex flex-1 shrink-0 items-end gap-4 sm:gap-8">
          <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
            {userMode === 'Edit'
              ? 'Edit provider details'
              : 'Add provider details'}
          </h2>
        </div>
      </div>
      <div className="grow overflow-y-scroll p-3 lg:p-4">
        <div className="container mx-auto max-w-4xl space-y-2 rounded-lg bg-white p-4 shadow dark:bg-neutral-800 sm:space-y-5">
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
            {renderTextField('Name', providerDetails?.name, 'name')}
            {renderTextField('URL', providerDetails?.endpoint, 'endpoint')}
            {renderSelectField(
              authenticationType,
              'Authentication type',
              'authenticationType',
              providerDetails?.authenticationType,
            )}
            {providerDetails?.authenticationType === 1 ||
            providerDetails?.authenticationType === '1' ? (
              <div className="grid gap-x-2 md:grid-cols-2">
                {renderTextField('Username', secret?.Username, 'Username')}
                {renderTextField(
                  'Password',
                  secret?.Password.trim(),
                  'Password',
                )}
              </div>
            ) : providerDetails?.authenticationType === 2 ||
              providerDetails?.authenticationType === '2' ? (
              <div>{renderTextField('API Key', secret?.ApiKey, 'ApiKey')}</div>
            ) : null}

            {renderSelectField(
              httpMethods,
              'Http verb',
              'httpVerb',
              providerDetails?.httpVerb,
            )}
            {renderTextField(
              'Refresh interval (hours)',
              providerDetails?.timeInterval,
              'timeInterval',
            )}
          </div>
        </div>
      </div>

      {renderFooter()}

      {showLoading && (
        <div className="bg-gray-900/8 absolute inset-0 z-20 grid place-items-center backdrop-blur-sm">
          <Spinner size="large" />
        </div>
      )}
    </main>
  );
}
