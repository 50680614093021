function isNullCheck(val: any) {
  if (val === null || val === undefined || val === '') return '';
  else return val;
}

function formatStore(store: any) {
  const value = {
    Title: store.PortalAlertObj.Title,
    Message: store.PortalAlertObj.Message,
    ExpirationDate: isNullCheck(store.PortalAlertObj.ExpirationDate),
    EffectiveDate: isNullCheck(store.PortalAlertObj.EffectiveDate.trim()),
    EnteredDate: isNullCheck(store.PortalAlertObj.EnteredDate),
    Id: store.PortalAlertObj.Id,
  };
  return value;
}

function isAlertDataEqual(initialData: any, currentData: any) {
  let tempInitialData = {
    ...initialData,
    EnteredDate: initialData.EnteredDate.replace(/\s+/g, ''),
  };
  let tempCurrentData = {
    ...currentData,
    EnteredDate: currentData.EnteredDate.replace(/\s+/g, ''),
  };

  delete tempInitialData['Id'];
  delete tempCurrentData['Id'];
  if (JSON.stringify(tempInitialData) === JSON.stringify(tempCurrentData)) {
    return true;
  } else return false;
}

export { formatStore, isAlertDataEqual };
