import React, { ReactElement, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { createMarkup } from 'src/utils/common';
import { activeStyles } from '../utils/constants';
import { formatDateWithTime } from 'src/utils/datepickerHelper';
import { usePortalAlertStore } from 'src/features/AddEditPortalAlerts/store/portalAlertStore';

import { PortalAlerts } from '../types/portalAlerts';

export type AlertListItemType = {
  alertInfo: PortalAlerts;
  selectedAlert: PortalAlerts | undefined;
  handleRowClick: (item: PortalAlerts) => void;
};

function ListItem({
  alertInfo,
  selectedAlert,
  handleRowClick,
}: AlertListItemType): ReactElement {
  const navigate = useNavigate();
  const queryClient: any = useQueryClient();

  const resetStore = usePortalAlertStore(
    useCallback(state => state?.resetStore, []),
  );
  return (
    <>
      <div
        tabIndex={0}
        className={`gap- relative flex flex-wrap gap-7 rounded bg-white p-2 shadow focus-visible:outline  focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-blue-600 dark:bg-neutral-800 dark:focus-visible:outline-blue-400 lg:gap-x-5 ${
          selectedAlert?.Id === alertInfo?.Id ? activeStyles : ''
        }`}
        onClick={() => {
          handleRowClick(alertInfo);
        }}
        onDoubleClick={() => {
          resetStore();
          queryClient.invalidateQueries(['get_portal_alerts'], {
            refetchActive: false,
          });
          navigate(`/PortalAlert/Edit/${alertInfo?.Id}`);
        }}
      >
        <div className="flex basis-[40%] flex-col gap-5 md:basis-[30%] lg:basis-1/5">
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Title
            </label>
            <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
              {alertInfo.Title}
            </p>
          </div>
        </div>
        <div className="flex basis-[40%] flex-col gap-8 md:basis-[30%] lg:basis-1/2">
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Message
            </label>
            <div
              className="prose max-w-[80ch] dark:prose-invert"
              dangerouslySetInnerHTML={createMarkup(alertInfo.Message || '')}
            ></div>
          </div>
        </div>
        <div className="flex basis-[40%] flex-col gap-8 md:basis-[30%] lg:basis-[12%]">
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Effective date
            </label>
            <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
              {alertInfo?.EffectiveDate != null
                ? formatDateWithTime(alertInfo.EffectiveDate)
                : ''}
            </p>
          </div>
        </div>
        <div className="flex basis-[40%] flex-col gap-5 md:basis-[30%] lg:basis-[12%]">
          <div>
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
              Expiration date
            </label>
            <p className="prevent-text-breakout break-words text-xs font-bold tracking-wide text-black dark:text-white">
              {alertInfo?.ExpirationDate != null
                ? formatDateWithTime(alertInfo.ExpirationDate)
                : ''}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export { ListItem };
