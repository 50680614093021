import { create } from 'zustand';
import produce from 'immer';

import { fetchPortalAlert } from './apiStore';

import { formatDateToRange } from 'src/utils/datepickerHelper';
import { initialState } from '../utils/constants';
import { isTinymceEditorContentEmpty } from 'src/utils/common';
import { formatStore, isAlertDataEqual } from '../utils/dataComparisonHelper';
import { useSaveChangesParamStore } from 'src/store/useSaveChangesParamStore';

import { AlertStoreType, PortalAlertObjType } from '../types/storeTypes';

export const usePortalAlertStore = create<AlertStoreType>((set, get) => ({
  isLoading: false,
  userMode: 'Create Portal Alerts',
  PortalAlertObj: initialState,
  currentData: {},
  initialAlertData: {},

  setUserMode: value => {
    set(
      produce(state => {
        state.userMode = value;
      }),
    );
  },

  updatePortalAlert: (key, value) => {
    set(
      produce(state => {
        state.PortalAlertObj = { ...state.PortalAlertObj, [key]: value };
      }),
    );
  },

  getPortalAlert: (token, tokenType, id, userMode) => {
    if (
      useSaveChangesParamStore.getState().isDirty === false ||
      useSaveChangesParamStore.getState().isSettingsPage === true
    ) {
      if (userMode === 'Edit portal alert') {
        set(
          produce(state => {
            state.isLoading = true;
          }),
        );

        fetchPortalAlert({ token, tokenType, id }).then(alertInfo => {
          set(
            produce(state => {
              state.PortalAlertObj = alertInfo;
              state.isLoading = false;
              state.PortalAlertObj.EnteredDate = formatDateToRange(
                alertInfo?.EffectiveDate,
                alertInfo?.ExpirationDate,
              );
            }),
          );
          const store = usePortalAlertStore.getState();

          set({
            initialAlertData: formatStore(store),
          });
        });
        set(
          produce(state => {
            state.isLoading = false;
          }),
        );
      }

      const storeValues = usePortalAlertStore.getState();
      const val = formatStore(storeValues);

      set(
        produce(state => {
          state.initialAlertData = val;
        }),
      );
    }
  },

  resetStore: () => {
    useSaveChangesParamStore.getState().setIsDirty(false);
    set(
      produce(state => {
        state.initialAlertData = {};
        state.PortalAlertObj = {
          Title: '',
          Message: '',
          EnteredDate: '',
          ExpirationDate: '',
          EffectiveDate: '',
          Id: 0,
        };
      }),
    );
  },

  disableSave: () => {
    const portalAlert: PortalAlertObjType = get().PortalAlertObj;
    return (
      portalAlert.Title === '' ||
      portalAlert.Message === '' ||
      isTinymceEditorContentEmpty(portalAlert.Message) ||
      portalAlert.EnteredDate === '' ||
      portalAlert.EnteredDate === null
    );
  },

  checkDataHasChanged: () => {
    const store = usePortalAlertStore.getState();
    set({
      currentData: formatStore(store),
    });

    if (Object.keys(get().initialAlertData).length === 0) return true;

    if (isAlertDataEqual(get().initialAlertData, get().currentData)) {
      useSaveChangesParamStore.getState().setIsDirty(false);
      return true;
    } else if (Object.keys(get().initialAlertData).length > 0) {
      useSaveChangesParamStore.getState().setIsDirty(true);
      return false;
    } else if (
      get().userMode !== 'Edit portal alert' &&
      Object.keys(get().initialAlertData).length === 0
    ) {
      useSaveChangesParamStore.getState().setIsDirty(false);
      const store = usePortalAlertStore.getState();

      set({
        initialAlertData: { ...formatStore(store) },
      });
      return false;
    } else return false;
    // return false;
  },
}));
