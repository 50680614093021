import { getBasicUserInfo } from 'src/utils/urls';

async function getUserBasicInfo({ id }: { id: string | null }) {
  if (id) {
    try {
      const response = await fetch(getBasicUserInfo(id), {
        method: 'GET',
      });
      if (response.status == 404) {
        throw new Error('User not found');
      }
      return response.json();
    } catch (error) {
      throw error;
    }
  }
}

export { getUserBasicInfo };
