import { useMutation, UseMutationResult } from 'react-query';

import { useAuth } from 'src/hooks';

import { bulkUploadUrl, changeUserPassword } from 'src/utils/urls';

export async function bulkUpload({
  requestData,
  token,
  tokenType,
}: {
  requestData: any;
  token: string;
  tokenType: string;
}): Promise<TResult> {
  const response = await fetch(bulkUploadUrl, {
    method: 'POST',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
    body: requestData,
  });
  return response.json();
}

export type ValidationError = { Message?: string };
export type TResult = string | ValidationError;
export type TVariables = {
  File: any;
};
export type TError = {
  message: string;
};
export type TSnapshot = unknown;

function setRequestData(params: any): FormData {
  const formData = new FormData();
  formData.append('File', params.file);
  return formData;
}

function useAddBulkUpload(): UseMutationResult<
  TResult,
  TError,
  TVariables,
  TSnapshot
> {
  const { token, tokenType } = useAuth();
  return useMutation(async (params: TVariables) => {
    const requestData = setRequestData(params);
    const result = await bulkUpload({
      requestData: requestData,
      token: token,
      tokenType: tokenType,
    });
    return result;
  });
}

export { useAddBulkUpload };
