import { create } from 'zustand';

import { useExtracoverageRequestStore } from 'src/features/AddEditExtraCoverageRequest/store/extracoverageStore';
import { usePersonAlertStore } from 'src/features/AddEditPersonAlert/store/personAlertStore';
import { usePortalAlertStore } from 'src/features/AddEditPortalAlerts/store/portalAlertStore';
import { useUserStore } from 'src/features/AddEditUsers/store/userStore';
import { useAddEditProviderStore } from 'src/features/Resources/store/addEditProviderStore';

type DataDirtyStore = {
  isDirty: boolean;
  setIsDirty: (val: boolean) => void;
  isSettingsPage: boolean;

  allowFetch: boolean;
  setAllowFetch: (val: boolean) => void;
  setIsSettingsPage: (val: boolean) => void;
  clearStore: () => void;
};

export const useSaveChangesParamStore = create<DataDirtyStore>(set => ({
  isDirty: false,
  allowFetch: false,
  showConfirmModal: false,
  isSettingsPage: false,

  setIsDirty: (val: boolean) => {
    set(() => ({
      isDirty: val,
    }));
  },
  setAllowFetch: (val: boolean) => {
    set(() => ({
      allowFetch: val,
    }));
  },
  setIsSettingsPage: (val: boolean) => {
    set(() => ({
      isSettingsPage: val,
    }));
  },
  clearStore: () => {
    usePersonAlertStore.getState().deleteAlertDetails();
    usePersonAlertStore.getState().deletePersonDetails();
    useUserStore.getState().deleteUserDetails();
    useExtracoverageRequestStore.getState().deleteCoverageDetails();
    useAddEditProviderStore.getState().clearProviderStore();
    usePortalAlertStore.getState().resetStore();
  },
}));
