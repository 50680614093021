import { useQuery, UseQueryResult, useQueryClient } from 'react-query';

import { useAuth } from 'src/hooks/useAuth';
import { downloadTemplate } from 'src/utils/urls';
import { showSuccessToast } from 'src/utils/ToastNotification';

async function fetchTemplate({
  token,
  tokenType,
}: TVaribles): Promise<TResult> {
  const response = await fetch(downloadTemplate, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  console.log('downloadTemplatedownloadTemplate fetch', response);
  return response.url;
}

export type TResult = any;
export type TError = { message: string };
export type TVaribles = {
  token: string;
  tokenType: string;
};

function useDownloadTemplate(): UseQueryResult<TResult, TError> {
  const { token, tokenType } = useAuth();
  const queryClient = useQueryClient();
  return useQuery(
    'get_download_template',
    async () => {
      const result = await fetchTemplate({ token, tokenType });
      //   queryClient.invalidateQueries('get_users_list');
      //   showSuccessToast({
      //     message: 'Re-invitation send successfully.',
      //   });
      console.log('downloadTemplatedownloadTemplate result', result);
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );
}

export { useDownloadTemplate };
