function formatData(value: any) {
  const tempData = {
    Name: value?.providerDetails?.name ? value?.providerDetails?.name : '',
    Endpoint: value?.providerDetails?.endpoint
      ? value?.providerDetails?.endpoint
      : '',
    AuthenticationType: value?.providerDetails?.authenticationType
      ? parseInt(value?.providerDetails?.authenticationType)
      : '',
    TimeInterval: value?.providerDetails?.timeInterval
      ? parseInt(value?.providerDetails?.timeInterval)
      : '',
    HttpVerb: value?.providerDetails?.httpVerb
      ? parseInt(value?.providerDetails?.httpVerb)
      : '',
    ApiKey: value?.secret?.ApiKey ? value?.secret?.ApiKey : '',
    Password: value?.secret?.Password ? value?.secret?.Password : '',
    ProviderId: value?.secret?.ProviderId ? value?.secret?.ProviderId : '',
    UserName: value?.secret?.Username ? value?.secret?.Username : '',
  };
  return tempData;
}
export { formatData };
