import { UseQueryResult, useQuery } from 'react-query';

import { useAuth } from 'src/hooks';

import { getQuickExitReasons } from 'src/utils/urls';

async function fetchReasons({
  token,
  tokenType,
}: TVariables): Promise<APIResult> {
  const response = await fetch(getQuickExitReasons, {
    method: 'GET',
    headers: {
      Authorization: `${tokenType} ${token}`,
    },
  });
  return response.json();
}

export type APIResult = Array<{
  Abbreviation: string;
  Id: number;
  IsReportRequired: boolean;
  Reason: string;
}>;

type TError = { message: string };
type TVariables = {
  token: string;
  tokenType: string;
};

function useGetQuickExitReasons(): UseQueryResult<APIResult, TError> {
  const { token, tokenType } = useAuth();
  return useQuery(
    ['qucik-exit-reasons'],
    async () => {
      const result = await fetchReasons({
        token,
        tokenType,
      });
      return result;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
}
export { useGetQuickExitReasons };
