import { create } from 'zustand';
import produce from 'immer';

import { fecthProviderDetails } from './apiStore';

import { checkValidation, urlPattern } from '../utils/resourceHelper';
import { formatData } from '../utils/dataComparison';
import { useSaveChangesParamStore } from 'src/store/useSaveChangesParamStore';

import { AddEditProviderStore } from '../types/addEditProvider';

export const useAddEditProviderStore = create<AddEditProviderStore>(
  (set, get) => ({
    errorMessage: '',
    providerDetails: {
      httpVerb: null,
      timeInterval: null,
      endpoint: '',
      name: '',
      authenticationType: null,
    },
    secret: {
      ProviderId: '',
      Username: '',
      Password: '',
      ApiKey: '',
    },
    userMode: '',
    saveClicked: false,
    isResponseLoading: false,
    authenticationIndication: null,
    initialProviderValue: {},

    updateProviderDetails: (key: string, value: string | number) => {
      set(
        produce(state => {
          state.providerDetails[key] = value;
        }),
      );
      get().checkDataHasChanged();
    },

    updateSecretDetails: (key: string, value: string | number) => {
      set(
        produce(state => {
          state.secret[key] = value;
        }),
      );
      get().checkDataHasChanged();
    },

    fetchProviderDetails: async (
      token: string,
      tokenType: string,
      id: string | undefined,
    ) => {
      if (
        useSaveChangesParamStore.getState().isSettingsPage === true ||
        useSaveChangesParamStore.getState().isDirty === false
      ) {
        if (id !== undefined) {
          set({
            isResponseLoading: true,
          });
          const response = await fecthProviderDetails({ token, tokenType, id });
          if (!urlPattern.test(response.endpoint)) {
            set(
              produce(state => {
                state.errorMessage = 'Invalid URL';
              }),
            );
          }
          set(
            produce(state => {
              state.providerDetails = { ...response };
              state.authenticationIndication = response?.authenticationType;
              state.isResponseLoading = false;
            }),
          );
          const storeValue = useAddEditProviderStore.getState();
          set(
            produce(state => {
              state.initialProviderValue = formatData(storeValue);
            }),
          );
        } else {
          const storeValue = useAddEditProviderStore.getState();
          set(
            produce(state => {
              state.initialProviderValue = formatData(storeValue);
            }),
          );
        }
      }
    },

    updateUserMode: (value: string) => {
      set(
        produce(state => {
          state.userMode = value;
        }),
      );
    },

    setSaveClicked: (value: boolean) => {
      set(
        produce(state => {
          state.saveClicked = value;
        }),
      );
    },
    disableSave: () => {
      const value = checkValidation(
        get().providerDetails,
        get().secret,
        get().userMode,
        get().authenticationIndication,
      );
      return value;
    },

    clearProviderStore: () => {
      set(state => {
        useSaveChangesParamStore.getState().setIsDirty(false);
        return {
          ...state,
          providerDetails: {},
          secret: {
            ProviderId: '',
            Username: '',
            Password: '',
            ApiKey: '',
          },
          saveClicked: false,
          authenticationIndication: null,
          errorMessage: '',
          initialProviderValue: {},
        };
      });
    },
    setErrorMessage: (val: string) => {
      set(
        produce(state => {
          state.errorMessage = val;
        }),
      );
    },
    checkDataHasChanged: () => {
      const storeValue = useAddEditProviderStore.getState();
      const updatedvalue = formatData(storeValue);

      if (Object.keys(get().initialProviderValue).length === 0) return true;

      if (
        JSON.stringify(updatedvalue) ===
        JSON.stringify(get().initialProviderValue)
      ) {
        useSaveChangesParamStore.getState().setIsDirty(false);
        return true;
      } else if (Object.keys(get().initialProviderValue).length > 0) {
        useSaveChangesParamStore.getState().setIsDirty(true);
        return false;
      } else if (
        get().userMode !== 'Edit' &&
        Object.keys(get().initialProviderValue).length === 0
      ) {
        useSaveChangesParamStore.getState().setIsDirty(false);
        const store = useAddEditProviderStore.getState();
        set({
          initialProviderValue: formatData(store),
        });
        return false;
      } else {
        return false;
      }
    },
  }),
);
