import { getPortalAlertInfo } from 'src/utils/urls';

import { PortalAlerts } from 'src/features/PortalAlerts/types/portalAlerts';

export async function fetchPortalAlert({
  token,
  tokenType,
  id,
}: {
  token: string;
  tokenType: string;
  id: string | undefined;
}): Promise<PortalAlerts | undefined> {
  if (id) {
    const response = await fetch(getPortalAlertInfo(id), {
      method: 'GET',
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    });
    return response.json();
  }
}
