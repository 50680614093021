import React, { ReactElement, Fragment, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { XMarkIcon, TrashIcon } from '@heroicons/react/20/solid';
import {
  ArrowDownTrayIcon as ArrowDownTrayIconMicro,
  ArrowUpTrayIcon as ArrowUpTrayIconMicro,
} from '@heroicons/react/16/solid';
import { Dialog, Transition } from '@headlessui/react';

import { Alert, Button } from 'src/ui/components';

import { useAddBulkUpload, useDownloadTemplate } from '../api';

import {
  dropzoneMsg,
  fileErrorMsg,
  fileFormatErrorMsg,
} from 'src/features/Settings/utils/constants';
import { progressSteps } from '../utils/bulkData';
import { classNames } from 'src/utils/className';

export function BulkUserUpload({
  isBulkUserModalOpen,
  setIsBulkUserModalOpen,
}: any): ReactElement {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [files, setFiles] = useState<any>([]);
  const [warning, setWarning] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [simulatingProgress, setSimulatingProgress] = useState<boolean>(false);
  const [errorState, setErrorState] = useState({
    errorType: '',
    isError: false,
  });
  const [successData, setSuccessData] = useState<any>({});

  const { refetch: refetchDownloadTemplate } = useDownloadTemplate();

  const { mutate: uploadFile, isLoading: isUploading } = useAddBulkUpload();

  function simulateProgress() {
    setSimulatingProgress(true);
    const interval = setInterval(() => {
      setUploadProgress(prevProgress => {
        const currentProgress = prevProgress || 0;
        if (currentProgress < 100) {
          return currentProgress + 10;
        } else {
          clearInterval(interval);
          setSimulatingProgress(false);
          return currentProgress;
        }
      });
    }, 300);
  }

  async function handleDrop(
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ) {
    setWarning('');

    const oversizedFiles = acceptedFiles.filter(
      (file: any) => file.size > 15 * 1024 * 1024,
    );
    if (rejectedFiles.length > 0) {
      setWarning(fileFormatErrorMsg);
      return;
    }
    if (oversizedFiles.length > 0) {
      setWarning(fileErrorMsg);
      return;
    }

    const file = acceptedFiles[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    if (fileExtension !== 'csv') {
      setWarning('Only CSV files are allowed.');
      return;
    }

    try {
      const reader = file.stream().getReader();
      let content = '';
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        content += new TextDecoder().decode(value);
      }

      const rows = content.split(/\r?\n/).filter(row => row.trim() !== '');
      console.log('Total rows:', rows.length);

      // Skip the first row (header row)
      const dataRows = rows.slice(1);
      console.log('Total rows:', rows.length, dataRows?.length);
      if (dataRows.length > 25) {
        setWarning(
          `The CSV file has too many rows. Max allowed: 25, Found: ${dataRows.length}`,
        );
        return;
      } else {
        const filesWithPreview = acceptedFiles?.map((file: any) =>
          Object.assign(file, { preview: URL.createObjectURL(file) }),
        );

        setFiles((prevFiles: any) => [...prevFiles, ...filesWithPreview]);
        setUploadProgress(0);
        simulateProgress();
      }
    } catch (error) {
      console.error('Error reading file:', error);
      setWarning('Failed to read the file.');
    }
  }

  function handleDragLeave(
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ) {
    // Same behavior as handleDrop, but triggered on drag leave event
    handleDrop(acceptedFiles, rejectedFiles);
  }

  function handleDragleaveEvent(e: any) {
    const items = e.dataTransfer?.items;
    if (items) {
      const acceptedFiles: File[] = [];
      const rejectedFiles: FileRejection[] = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i].getAsFile();
        if (item) {
          // Check if the file is a PDF and if it's within the size limit
          if (item.type === 'text/csv' && item.size <= 15 * 1024 * 1024) {
            acceptedFiles.push(item);
          } else {
            rejectedFiles.push({
              file: item,
              errors: [
                {
                  code: 'file-invalid-type',
                  message: 'Invalid file type',
                },
              ],
            });
          }
        }
      }

      handleDragLeave(acceptedFiles, rejectedFiles);
    }
  }

  const handleCancel = () => {
    setFiles([]);
    setWarning('');
    setUploadProgress(0);
    setSimulatingProgress(false);
  };

  function handleFileChange() {
    const file = files[0];
    var fileName = file?.name;
    let fileModules = fileName.split('.');
    let fileExtension = fileModules[fileModules.length - 1];

    if (file.name != null && file.name != '' && file.size < 15728640) {
      let docParam: any;
      const reader = new FileReader();

      reader.onload = e => {
        if (fileExtension === 'csv') {
          docParam = {
            file: file,
          };

          uploadFile(docParam, {
            onSuccess: (data: any) => {
              setSuccessData(data);
              setFiles([]);
            },
          });
        } else {
          setErrorState({ errorType: fileFormatErrorMsg, isError: true });
        }
      };
      reader.readAsDataURL(file);
    }
    setFiles([]);
  }

  function renderfileName() {
    return (
      <>
        <p id={files[0].name} className="text-balance text-xs">
          {files[0].name}
        </p>
        <Button
          variant="subtle-danger"
          size="small"
          onClick={e => {
            e.stopPropagation();
            handleCancel();
          }}
        >
          <TrashIcon className="size-5" />
        </Button>
      </>
    );
  }

  function renderProgressBar() {
    return (
      <>
        <div className="relative w-52 pt-1">
          <div className="mb-2 flex items-center justify-center">
            <div className="">
              <span className="inline-block rounded-full bg-primary-100 px-2 py-1 text-xs font-semibold uppercase text-primary-700 dark:bg-primary-900 dark:text-primary-200">
                {uploadProgress || 0}%
              </span>
            </div>
          </div>
          <div className="mb-2 flex h-2 overflow-hidden rounded bg-gray-200 text-xs dark:bg-neutral-600">
            <div
              style={{
                width: `${uploadProgress || 0}%`,
              }}
              className="flex flex-col bg-primary-500 text-center text-white"
            />
          </div>
        </div>
      </>
    );
  }

  function step1() {
    return (
      <div className="container prose prose-neutral mx-auto min-h-52 max-w-2xl dark:prose-invert">
        <p className="font-semibold">
          Please follow the instructions when filling out the template file{' '}
          <Button
            variant="link"
            className="relative -bottom-[3px] -ml-1 !gap-1"
            onClick={() =>
              refetchDownloadTemplate().then(data => {
                window.open(data.data);
              })
            }
          >
            <ArrowDownTrayIconMicro className="size-4" /> Download template
          </Button>
        </p>
        <div>
          <ol className="!mt-0 list-decimal text-sm">
            <li>
              Ensure the file to be uploaded is in CSV format and does not
              exceed 15MB in size.
            </li>
            <li>Each file can include a maximum of 25 users.</li>
            <li>
              Enter the account number in the CIDSIDPID format (e.g.,
              771700077717).
            </li>
            <li>
              Users granted Call Document access permission will be given full
              access. If limited access is needed, this should be manually
              changed after the user is created.
            </li>
            <li>
              To set a custom time for email notifications, this should be
              manually changed after the user is created.
            </li>
          </ol>
        </div>
      </div>
    );
  }

  function step2() {
    return (
      <>
        <section className="flex min-h-52 cursor-pointer flex-col items-center space-y-2 rounded-lg border-2 border-dashed border-gray-500/25 bg-gray-50 text-center hover:border-primary-500 hover:bg-gray-200/40 dark:border-gray-200/25 dark:bg-neutral-800 dark:hover:border-primary-700 dark:hover:bg-neutral-700/50">
          <Dropzone
            accept={{
              'text/csv': ['.csv'],
            }}
            onDrop={(acceptedFiles, rejectedFiles) => {
              handleDrop(acceptedFiles, rejectedFiles);
            }}
            onDragLeave={e => {
              handleDragleaveEvent(e);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section
                {...getRootProps()}
                className="flex h-full w-full grow flex-col items-center justify-center space-y-4"
              >
                <input {...getInputProps()} />
                {!simulatingProgress && files && files.length === 0 && (
                  <div>
                    <p className="text-balance text-sm font-semibold">
                      {dropzoneMsg}
                    </p>
                    <p className="text-balance text-xs leading-5 text-gray-600 dark:text-neutral-400">
                      File should be in csv format and maximum file size is
                      15MB.
                    </p>
                  </div>
                )}

                {!simulatingProgress &&
                  files &&
                  files.length > 0 &&
                  renderfileName()}

                {!warning && simulatingProgress && renderProgressBar()}
                {warning !== '' && (
                  <Alert variant="danger" size="small">
                    {warning}
                  </Alert>
                )}
              </section>
            )}
          </Dropzone>
        </section>
      </>
    );
  }

  function step3() {
    return (
      <>
        {isUploading && (
          <div className="grid min-h-52 place-content-center">
            <div className="grid place-content-center">
              <div className="icon-document">
                <figure>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="size-24 fill-gray-400 dark:fill-neutral-500"
                  >
                    <path
                      className="animate-growFromLeft"
                      d="M367.125,265.752h-218c-2.761,0-5-2.239-5-5v0c0-2.761,2.239-5,5-5h218c2.761,0,5,2.239,5,5v0
                             C372.125,263.513,369.887,265.752,367.125,265.752z"
                    />
                    <path
                      className="animate-growFromLeft"
                      style={{ animationDelay: '75ms' }}
                      d="M367.125,298h-218c-2.761,0-5-2.239-5-5l0,0c0-2.761,2.239-5,5-5h218c2.761,0,5,2.239,5,5l0,0
                             C372.125,295.761,369.887,298,367.125,298z"
                    />
                    <path
                      className="animate-growFromLeft"
                      style={{ animationDelay: '150ms' }}
                      d="M367.125,330.249h-218c-2.761,0-5-2.239-5-5v0c0-2.761,2.239-5,5-5h218c2.761,0,5,2.239,5,5v0
                             C372.125,328.01,369.887,330.249,367.125,330.249z"
                    />
                    <path
                      className="animate-growFromLeft"
                      style={{ animationDelay: '225ms' }}
                      d="M367.125,362.497h-218c-2.761,0-5-2.239-5-5l0,0c0-2.761,2.239-5,5-5h218c2.761,0,5,2.239,5,5l0,0
                             C372.125,360.258,369.887,362.497,367.125,362.497z"
                    />
                    <path
                      className="animate-growFromLeft"
                      style={{ animationDelay: '300ms' }}
                      d="M367.125,394.745h-218c-2.761,0-5-2.239-5-5v0c0-2.761,2.239-5,5-5h218c2.761,0,5,2.239,5,5v0
                             C372.125,392.507,369.887,394.745,367.125,394.745z"
                    />
                    <path
                      d="M417.128,190.766L289.41,65.258c-4.901-5.379-11.961-8.758-19.814-8.758H111.799C96.998,56.5,85,68.498,85,83.299v345.401
               c0,14.801,11.998,26.799,26.799,26.799h288.401c14.801,0,26.799-11.998,26.799-26.799V211.538
               C427,203.16,423.152,195.68,417.128,190.766z M296.33,100.097l86.091,84.601l-59.578-0.136
               c-14.818-0.034-26.796-12.086-26.738-26.904L296.33,100.097z M407,428.701c0,3.749-3.05,6.799-6.799,6.799H111.799
               c-3.749,0-6.799-3.05-6.799-6.799V83.299c0-3.749,3.05-6.799,6.799-6.799h157.796c2.457,0,4.073,1.254,4.817,2.001
               c0.104,0.105,0.22,0.231,0.339,0.37l-0.011,0.011l0.097,0.096c0.716,0.867,1.564,2.317,1.557,4.348l-0.29,74.253
               c-0.049,12.515,4.783,24.295,13.604,33.172c8.822,8.877,20.573,13.781,33.087,13.81l77.419,0.177
               c1.4,0.003,2.702,0.433,3.783,1.164l0.742,0.729l0.072-0.073c1.341,1.243,2.187,3.013,2.187,4.979V428.701z"
                    />
                  </svg>
                </figure>
              </div>
            </div>
            <p className="text-gray-600 dark:text-neutral-400">
              Please wait, we&apos;re checking for any errors in the uploaded
              document.
            </p>
          </div>
        )}

        {/* Step 3.2 */}
        {!isUploading && successData?.IsUserCreationInitiated && (
          <div className="container mx-auto grid min-h-52 max-w-xl place-content-center">
            <div className="flex flex-col items-center gap-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="size-16 stroke-green-500"
              >
                <path d="M21.801 10A10 10 0 1 1 17 3.335" />
                <path d="m9 11 3 3L22 4" />
              </svg>
              <div className="text-center">
                <p className="font-semibold">
                  User creation successfully initiated.
                </p>
                <p className="text-gray-700 dark:text-neutral-400">
                  You will receive an email when the user creation process is
                  completed.
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Step 3.3 */}
        {!isUploading && !successData?.IsValidRequest && (
          <div className="container mx-auto grid min-h-52 max-w-2xl place-content-center">
            <div className="flex w-full flex-col items-center gap-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="size-16 stroke-red-500"
              >
                <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                <path d="M12 9v4" />
                <path d="M12 17h.01" />
              </svg>

              <div className="prose prose-neutral dark:prose-invert">
                <p className="mb-3 font-semibold !leading-tight">
                  Please reupload the file, as we were unable to process it due
                  to the issue(s) mentioned below.{' '}
                  <Button
                    variant="link"
                    className="relative -bottom-[3px] -ml-1 !gap-1"
                    onClick={() => setCurrentStep(2)}
                  >
                    <ArrowUpTrayIconMicro className="size-4" /> Upload file
                  </Button>
                </p>

                <ol className="mt-0 list-decimal text-sm">
                  {successData?.BulkUsers?.length > 0 &&
                    successData?.BulkUsers?.map((item: any, index: number) => {
                      if (item?.Comments !== null) {
                        return item.Comments.includes('\n')
                          ? item.Comments.split('\n').map(
                              (line: any, lineIndex: number) => {
                                return (
                                  line !== '' && (
                                    <li key={`${index}-${lineIndex}`}>
                                      {line}
                                    </li>
                                  )
                                );
                              },
                            )
                          : item.Comments !== '' && (
                              <li key={index}>{item.Comments}</li>
                            );
                      } else return null;
                    })}
                  {successData?.Exception !== '' && (
                    <li>{successData?.Exception}</li>
                  )}
                </ol>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Transition show={isBulkUserModalOpen} appear as={Fragment}>
        <Dialog
          className="relative z-10"
          onClose={() => setIsBulkUserModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pr-dialog__backdrop" />
          </Transition.Child>
          <div className="pr-dialog__wrapper">
            <div className="pr-dialog__panel__container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="pr-dialog__panel flex max-h-[calc(-90px+100svh)] flex-col sm:!max-w-5xl">
                  <div className="flex items-center gap-3 px-4 py-3">
                    <Dialog.Title
                      as="h3"
                      className="grow truncate text-lg font-medium text-gray-900 dark:text-white"
                    >
                      Bulk upload users
                    </Dialog.Title>
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => {
                        setIsBulkUserModalOpen(false);
                        setCurrentStep(1);
                        handleCancel();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <nav aria-label="progress">
                    <ol className="border-y border-gray-300 dark:border-neutral-700 md:flex">
                      {progressSteps?.map((step, index) => (
                        <li
                          className={`${
                            currentStep === index + 1
                              ? 'pr-bulkUpload-isCurrent group relative md:flex md:flex-1'
                              : 'group relative md:flex md:flex-1'
                          }`}
                          key={index}
                        >
                          <div className="flex items-center px-6 py-2 text-sm font-medium">
                            <span className="flex size-10 shrink-0 items-center justify-center rounded-full border-2 border-gray-400 text-gray-500 group-[.pr-bulkUpload-isCurrent]:border-primary-700 group-[.pr-bulkUpload-isCurrent]:text-primary-700 dark:border-neutral-500 dark:text-neutral-400 dark:group-[.pr-bulkUpload-isCurrent]:text-primary-500">
                              {`${0}${index + 1}`}
                            </span>
                            <span className="ml-4 text-sm text-gray-500 group-[.pr-bulkUpload-isCurrent]:font-medium group-[.pr-bulkUpload-isCurrent]:text-primary-700 dark:text-neutral-400 dark:group-[.pr-bulkUpload-isCurrent]:text-primary-500 ">
                              {step}
                            </span>
                          </div>
                          <div className="absolute right-0 top-0 hidden h-full w-5 md:block">
                            <svg
                              fill="none"
                              viewBox="0 0 22 80"
                              preserveAspectRatio="none"
                              className="size-full text-gray-300 dark:text-neutral-700"
                            >
                              <path
                                d="M0 -2L20 40L0 82"
                                stroke="currentcolor"
                                vectorEffect="non-scaling-stroke"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </li>
                      ))}
                    </ol>
                  </nav>

                  <div className="relative min-h-0 grow overflow-y-scroll scroll-smooth p-4">
                    {/* Step 1 */}
                    {currentStep === 1 && step1()}

                    {/* Step 2 */}
                    {currentStep === 2 && step2()}
                    {/* Step 3 */}
                    {currentStep === 3 && step3()}
                  </div>
                  <div className="flex gap-[1ch] border-t border-gray-300 bg-gray-50 p-2 px-2 py-4 dark:border-neutral-700 dark:bg-neutral-700/50 sm:justify-between sm:p-3">
                    {/* {currentStep !== 1 && ( */}
                    <div
                      className={classNames(
                        currentStep === 1 ? 'hidden sm:block' : '',
                      )}
                    >
                      <Button
                        className={classNames(
                          currentStep === 1
                            ? 'pointer-events-none opacity-0'
                            : '',
                        )}
                        disabled={currentStep === 3}
                        onClick={() => {
                          if (currentStep > 1) setCurrentStep(prev => prev - 1);
                        }}
                      >
                        Back
                      </Button>
                    </div>
                    {/* )} */}

                    <div className="flex w-full gap-[1ch] sm:w-auto">
                      {currentStep === 3 && (
                        <Button
                          onClick={() => {
                            setCurrentStep(1);
                            setIsBulkUserModalOpen(false);
                            setWarning('');
                          }}
                          className="grow sm:grow-0"
                        >
                          Close
                        </Button>
                      )}

                      {currentStep !== 3 && (
                        <>
                          <Button
                            onClick={() => {
                              setCurrentStep(1);
                              setIsBulkUserModalOpen(false);
                              //   setWarning('');
                              handleCancel();
                            }}
                            className="grow sm:grow-0"
                          >
                            Cancel
                          </Button>

                          <Button
                            variant="primary"
                            disabled={
                              currentStep === 2 &&
                              (files[0] === undefined || simulatingProgress)
                                ? true
                                : false
                            }
                            onClick={() => {
                              if (currentStep < 3)
                                setCurrentStep(prev => prev + 1);
                              if (currentStep === 2) handleFileChange();
                            }}
                            className="grow sm:grow-0"
                          >
                            {currentStep === 1
                              ? 'Continue'
                              : 'Initiate user creation'}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
