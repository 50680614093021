import React, {
  ReactElement,
  Fragment,
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
  FunnelIcon,
  ArrowPathIcon,
  UserPlusIcon,
  ArrowDownTrayIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import {
  UserGroupIcon as UserGroupIconMicro,
  ArrowDownTrayIcon as ArrowDownTrayIconMicro,
  ArrowUpTrayIcon as ArrowUpTrayIconMicro,
} from '@heroicons/react/16/solid';
import { Disclosure, Dialog, Transition } from '@headlessui/react';

import {
  Button,
  EmptyState,
  Spinner,
  Skeleton,
  Alert,
} from 'src/ui/components';
import {
  UserActions,
  UserListItem,
  UserFilter,
  BulkUserUpload,
} from './components';

import {
  useGetPermissionGroupCode,
  useTitle,
  useGetPortalUserGroupCode,
  useGetStoredPermissions,
} from 'src/hooks';
import {
  useGetUsersList,
  useDownloadCsv,
  useGetReInvite,
  useChangeEmail,
} from './api';

import { useUsersLogFilter } from './utils/useFilterReducer';
import { pageLength } from 'src/utils/appConstants';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';
import { useUserStore } from 'src/features/AddEditUsers/store/userStore';
import { downloadCsvMsg } from 'src/utils/appConstants';
import { formatSelectOptions } from '../CallDocuments/utils/callDocumentHelperFunctions';
import { statusOptions } from './utils/sortLists';

import { UsersListType, UserListDataType } from './types/usersListType';
import { PermissionGroupCodeType } from 'src/types/permissionGroupCode';

export default function Users(): ReactElement {
  useTitle('View Users');

  const navigate = useNavigate();
  const queryClient: any = useQueryClient();

  const permission = useGetStoredPermissions();

  const tbodyRef = useRef() as React.MutableRefObject<HTMLTableSectionElement>;

  const [selectedUser, setSelectedUser] = useState<UsersListType | undefined>();

  const [isBulkUserModalOpen, setIsBulkUserModalOpen] = useState(false);

  const {
    filterOptions,
    filterCount,
    setValues,
    setPermission,
    setStatus,
    resetFilters,
  } = useUsersLogFilter();

  const { data: portalUserGroupCode, isLoading: isPostalUserGroupCodeLoading } =
    useGetPortalUserGroupCode();

  const { data: userPersmissions, isLoading: isUserPermissionLoading } =
    useGetPermissionGroupCode(portalUserGroupCode || '');

  const isSelectBlank = useMemo(() => {
    if (
      (filterOptions && filterOptions.status?.length === 0) ||
      filterOptions.permission?.length === 0
    ) {
      return true;
    } else return false;
  }, [filterOptions]);

  const {
    data,
    isLoading: isListLoading,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetUsersList(filterOptions);

  const { mutate: downloadcsv, isLoading: downloadCsvLoading } =
    useDownloadCsv();

  const { mutate: onEmailChange, isLoading: isEmailChangeLoading } =
    useChangeEmail();

  const { refetch: refetchReinvite, isLoading: reinviteLoading } =
    useGetReInvite(selectedUser?.Id || '');

  const updateUserMode = useUserStore(
    useCallback(state => state.updateUserMode, []),
  );

  const isOnboardingCheckLoading = useUserStore(
    useCallback(state => state.isOnboardingCheckLoading, []),
  );

  const deleteUserDetails = useUserStore(
    useCallback(state => state.deleteUserDetails, []),
  );

  const isNonBlockingModal =
    isFetchingNextPage ||
    isRefetching ||
    downloadCsvLoading ||
    isEmailChangeLoading ||
    reinviteLoading ||
    isOnboardingCheckLoading;

  const isSkeletonLoading =
    isListLoading ||
    isFetching ||
    isPostalUserGroupCodeLoading ||
    isUserPermissionLoading;

  const handleScroll = useCallback(
    (e: any) => {
      if (tbodyRef.current === e.target) {
        const bottom =
          e.target.clientHeight - 10 <
            e.target.scrollHeight - e.target.scrollTop &&
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 10;
        if (bottom) {
          hasNextPage && fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);
  const containerRef = useRef<any>();

  // for updating the selected item if grid api gets refetched
  useEffect(() => {
    if (
      data &&
      data?.pages[0]?.data?.length &&
      selectedUser?.Id !== undefined
    ) {
      let newItem = data?.pages[0]?.data?.find(
        dataItem => dataItem?.Id === selectedUser?.Id,
      );
      if (newItem !== undefined) {
        setSelectedUser(newItem);
      }
    }
  }, [data, selectedUser?.Id]);

  function handleRowClick(item: UsersListType) {
    if (item.Id !== selectedUser?.Id) {
      setSelectedUser(item);
    } else {
      setSelectedUser(undefined);
    }
  }

  function handleDownloadAsCsv() {
    downloadcsv(
      { filterOptions },
      {
        onSuccess: () => {
          showSuccessToast({
            message: downloadCsvMsg,
          });
        },
        onError: (errorMessage: any) => {
          showErrorToast(errorMessage);
        },
      },
    );
  }

  function resetFunction() {
    resetFilters();
    setSelectedUser(undefined);
  }

  function getAdminPermissionId(): number | undefined {
    return userPersmissions?.find(
      (x: PermissionGroupCodeType) => x.ClaimType === 'CanAdministerUsers',
    )?.Id;
  }

  const permissions = useMemo(() => {
    if (userPersmissions) {
      return formatSelectOptions(userPersmissions, true);
    }
  }, [userPersmissions]);

  useEffect(() => {
    if (permissions && !filterOptions?.permission) {
      setPermission([...permissions]);
    }
  }, [filterOptions?.permission, permissions, setPermission]);

  useEffect(() => {
    if (statusOptions && !filterOptions?.status) {
      setStatus([...statusOptions]);
    }
  }, [filterOptions?.status, setStatus]);

  function renderCount(): ReactElement {
    return (
      <>
        {data?.pages &&
          data.pages.length > 0 &&
          data?.pages[0]?.data?.length > 0 &&
          data.pages[data.pages.length - 1]?.data.length && (
            <p className="shrink text-xs text-gray-600 dark:text-neutral-400">
              <span>
                Showing 1 to{' '}
                <strong>
                  {' '}
                  {data?.pages[data?.pages?.length - 1]?.data?.length ===
                  pageLength
                    ? data?.pages?.length * pageLength
                    : (data?.pages?.length - 1) * pageLength +
                      data?.pages[data?.pages?.length - 1]?.data.length}{' '}
                </strong>{' '}
                of{' '}
                <strong>
                  {' '}
                  {data?.pages[data?.pages?.length - 1]?.recordsFiltered}
                </strong>{' '}
                entries (filtered from{' '}
                <strong> {data?.pages[0]?.recordsTotal} </strong> total entries)
                {isFetching ? ' | Processing' : ''}
              </span>
            </p>
          )}
      </>
    );
  }

  function disclosureContent(): ReactElement {
    return (
      <Disclosure>
        {({ open }) => (
          <>
            <div className="flex items-center justify-between gap-4 border-t border-gray-200 bg-white px-2 py-1 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:px-3 lg:px-4">
              <div className="flex min-w-0 flex-1 items-end gap-4 sm:gap-6">
                <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
                  Users
                </h2>
                <div className="mt-1 flex items-center gap-2 sm:mt-0">
                  <div className="relative inline-flex">
                    <Disclosure.Button
                      as={Button}
                      size="small"
                      variant={open ? 'primary' : 'subtle'}
                    >
                      <FunnelIcon
                        className="-ml-0.5 h-4 w-4"
                        aria-hidden="true"
                      />
                      <span className="hidden sm:inline-block">Filter</span>
                    </Disclosure.Button>

                    <span className="absolute right-0 top-0 -mr-1.5 -mt-1.5 flex h-5 w-5">
                      {filterCount > 0 ? (
                        <span
                          className={`relative inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs font-bold leading-none text-white`}
                        >
                          {filterCount}
                        </span>
                      ) : null}
                    </span>
                  </div>

                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => {
                      resetFunction();
                    }}
                  >
                    <ArrowPathIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">Reset</span>
                  </Button>
                </div>
              </div>
              <div className="flex gap-2">
                {permission?.AllProtocallUsers &&
                  permission?.RelationshipManager && (
                    <Button
                      variant="primary"
                      size="small"
                      onClick={() => setIsBulkUserModalOpen(true)}
                    >
                      <UserGroupIconMicro
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span className="hidden sm:block">Bulk upload users</span>
                    </Button>
                  )}

                <Button
                  variant="primary"
                  size="small"
                  onClick={() => {
                    updateUserMode('');
                    deleteUserDetails();
                    navigate('/User/Create');
                    queryClient.invalidateQueries(['create-edit-user'], {
                      refetchActive: false,
                    });
                  }}
                >
                  <UserPlusIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="hidden sm:block">Create new user</span>
                </Button>
                {data && data.pages[0].data?.length ? (
                  <Button
                    variant="subtle"
                    size="small"
                    onClick={() => {
                      handleDownloadAsCsv();
                    }}
                  >
                    <ArrowDownTrayIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">Download as CSV</span>
                  </Button>
                ) : null}
              </div>
            </div>

            {/* Filter */}
            <UserFilter
              setValues={setValues}
              setPermission={setPermission}
              setStatus={setStatus}
              filterOptions={filterOptions}
              resetFilters={resetFilters}
              permissions={permissions}
            />
          </>
        )}
      </Disclosure>
    );
  }

  return (
    <>
      <main className="flex flex-1 flex-col overflow-y-auto overscroll-contain">
        {disclosureContent()}
        <div className="flex min-h-[40px] items-center justify-between gap-x-4 gap-y-2 px-2 py-1 sm:px-3 md:flex-wrap lg:px-4">
          {!isSelectBlank && renderCount()}
          {!isSelectBlank &&
            data?.pages &&
            data?.pages[0]?.data?.length > 0 &&
            selectedUser?.Id !== '' &&
            selectedUser !== undefined && (
              <>
                <UserActions
                  userData={selectedUser}
                  adminPermissionId={getAdminPermissionId()}
                  refetchReinvite={refetchReinvite}
                  onEmailChange={onEmailChange}
                  setSelectedUser={setSelectedUser}
                />
              </>
            )}
        </div>
        <div
          className="relative flex-1 space-y-2 overflow-y-scroll px-2 pb-2 sm:px-3 sm:pb-3 lg:px-4 lg:pb-4"
          ref={tbodyRef}
        >
          {!isSelectBlank && !isListLoading && data?.pages ? (
            <>
              {data?.pages.map((page: UserListDataType, index: number) => (
                <Fragment key={`${page} - ${index + 1}`}>
                  {page?.data && page?.data?.length ? (
                    page?.data.map((userInfo: UsersListType, indx: number) => (
                      <UserListItem
                        key={`${userInfo.Id}-${indx + 1}`}
                        userInfo={userInfo}
                        permissions={userPersmissions}
                        selectedUser={selectedUser}
                        handleRowClick={handleRowClick}
                        containerRef={tbodyRef}
                      />
                    ))
                  ) : (
                    <div className="absolute inset-0 grid place-content-center">
                      <EmptyState message="No information available" />
                    </div>
                  )}
                </Fragment>
              ))}
            </>
          ) : (
            <>
              {isSkeletonLoading && <Skeleton count={8} />}
              {isSelectBlank && !isSkeletonLoading && (
                <div className="absolute inset-0 grid place-content-center">
                  <EmptyState message="No information available" />
                </div>
              )}
            </>
          )}
          {isNonBlockingModal && (
            <div className="fixed inset-0 z-20 grid place-content-center">
              <Spinner size="large" />
            </div>
          )}
        </div>
      </main>

      {/* Bulk user modal */}
      <BulkUserUpload
        isBulkUserModalOpen={isBulkUserModalOpen}
        setIsBulkUserModalOpen={setIsBulkUserModalOpen}
      />
    </>
  );
}
